<template>
  <v-container fluid style="padding-top: 0px; margin: 0px">
    <v-row>Filter</v-row>
    <v-row
      style="background-color: #fff; padding-bottom: 0px; padding-top: 0px"
    >
      <v-col class="special-col" cols="2">
        <v-text-field
        @keypress="$store.state.selectedItemsOnly = false"
          label="Product"
          v-model="$store.state.filterSearch"
        ></v-text-field>
      </v-col>
      <v-col style="padding-right: 0px; display: flex" class="special-col">
        <v-select
          label="Category"
          :items="categories"
          v-model="$store.state.filterCategory"
        >
        </v-select>
        <v-btn
          icon
          color="orange"
          @click="$store.state.filterCategory = ''"
          x-large
        >
          <v-icon>mdi-filter-remove</v-icon>
        </v-btn>
      </v-col>
      <v-col
        style="padding-right: 0px; display: flex"
        class="special-col"
      >
        <v-select
          label="Sub Category"
          :items="subCategories"
          v-model="$store.state.filterSubCategory"
        >
        </v-select>
        <v-btn
          icon
          color="orange"
          @click="$store.state.filterSubCategory = ''"
          x-large
        >
          <v-icon>mdi-filter-remove</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-select 
            label="Steel type" 
            :items="['Any', 'Weathering steel', 'Galvanised steel']"
            v-model="$store.state.steelFilter"></v-select>
      </v-col>
      <v-col style="white-space: nowrap" class="special-col">
        <v-checkbox
        v-model="$store.state.selectedItemsOnly"
          label="Show selected items only"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    filterSearch() {
      return this.$store.state.filterSearch;
    },
    filterCategory() {
      return this.$store.state.filterCategory;
    },
    filterSelectedItems() {
      return this.$store.state.selectedItemsOnly;
    },
    categories() {
      if (typeof this.items.length === "undefined") return [];
      const arr = this.items.map((item) => item.category).sort();
      return arr;
    },
    subCategories() {
      if (!this.filterCategory) return [];
      return this.items
        .filter((item) => item.category === this.filterCategory)
        .map((item) => item.subCategory)
        .sort();
    },
    items() {
      return this.$store.state.inventory[0] === undefined
        ? []
        : this.$store.state.inventory || [];
    },
  },
  watch: {
    filterCategory: function () {
      this.$store.state.filterSubCategory = "";
    },
    filterSelectedItems: function (now, prev) {
      console.log(`now: ${now}, prev: ${prev}`)
      this.$store.state.filterSearch = '';
    },
  },
};
</script>

<style>
.special-col {
  padding-bottom: 0px !important;
}
</style>