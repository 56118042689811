<template>
    <v-container fluid fill-height>
      <v-row class="padless">
        <container-module />
      </v-row>
      <v-row class="padless">
        <order-assistant />
      </v-row>
      <v-row class="padless">
        <item-filter />
      </v-row>
      <v-row class="padless">
        <item-list />
      </v-row>
    </v-container>
</template>

<script>
import ContainerModule from '../components/ContainerModule';
import OrderAssistant from '../components/OrderAssistant';
import ItemFilter from '../components/ItemFilter';
import ItemList from "../components/ItemList";

export default {
  components: {
    ContainerModule,
    OrderAssistant,
    ItemFilter,
    ItemList,
  },
};
</script>

<style>
.total {
  white-space: nowrap;
}
.cart-header {
  font-size: 13px !important;
  white-space: nowrap !important;
  padding: 0px !important;
}
.padless {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>